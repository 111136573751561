import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'
import { Location } from '../interfaces/Location.interface'
import { WorkItem } from '../interfaces/WorkItem.interface'
import Report from './utils/report'
import { ref } from 'vue'
import { useQrStore } from './qr'
import { useLoginStore } from './login'

const $baseUrl = process.env.VUE_APP_API_BASE_URL;
const $topSecretKey = process.env.VUE_APP_API_SECRET_KEY;
const $serviceFileFolder = process.env.VUE_APP_SERVICE_FILE_FOLDER;

export const useLocationPlanStore = defineStore('locationPlan', () => {
    const location = ref<Location|null>(null);
    const locationFound = ref<boolean>(false);
    const misc = ref('');
    const error = ref('');

    const { qrCode } = storeToRefs(useQrStore());
    const { contractorId } = storeToRefs(useLoginStore());

    const getLocation = async (id: number): Promise<void> => {
        const path = $baseUrl + '/items/Locations';
        const options: any = {
            headers: {
                Authorization: 'Bearer ' + $topSecretKey
            },
            params: {
                fields: [
                    'id',
                    'Title',
                    'WorkItems',
                    'WorklistFile',
                    'Address',
                    'sort_type',
                    'sort',
                    'OperationsMap',
                    'WorkItems.*',
                    'WorkItems.Service.*'
                ],

                filter: {
                    _and: [
                        {
                            id: {
                                _eq: id
                            }
                        },
                        {
                            status: {
                                _neq: 'archived'
                            }
                        }
                    ]
                }
            }
        }

        return new Promise((resolve) => {
            axios.get(path, options)
            .then((response: {data: any}) => {
                if (response.data.data.length === 0) {
                    error.value = "Kunne ikke finde lokationen..";
                    return;
                }

                location.value = response.data.data[0];
                if (!location.value) {
                    return;
                }

                location.value.WorkItems = location.value.WorkItems.filter((workItem: WorkItem) => {
                    if (workItem.Service === null) {
                        return false;
                    }

                    if (qrCode.value === null && workItem.Service.Allow_GPS === false) {
                        return false;
                    }

                    return true;
                });

                location.value.WorkItems.map((workItem: WorkItem) => {
                    workItem.checked = false;
                    workItem.images = [];
                    return workItem;
                });

                location.value.WorkItems.sort((a: WorkItem, b: WorkItem) => {

                    let nameA: number|string = a.Service.Title.toUpperCase();
                    let nameB: number|string = b.Service.Title.toUpperCase();

                    if (location.value && location.value.sort_type !=='alphabetical') {
                        nameA = a.sort;
                        nameB = b.sort;
                    }

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                locationFound.value = true;
                resolve();
            })
            .catch((error:any) => {
                error.value = 'fejl:' + error;
                resolve();
            })
        });
    }

    const toggleWorkItem = async (workItem: WorkItem): Promise<void> => {
        workItem.checked = !workItem.checked;
    }

    const addSnapshotToWorkItem = async (workItem: WorkItem, image: Blob): Promise<void> => {
        workItem.images.push(image);
    }

    const deleteSnapshotFromWorkItem = async (workItem: WorkItem, image: number): Promise<void> => {
        workItem.images.splice(image, 1);
    }

    const submitReport = async (): Promise<void> => {
        return new Report(location.value!, contractorId.value ?? 0, misc.value, $baseUrl!, $topSecretKey!, $serviceFileFolder!).sendReport();
    }

    return {
        location,
        locationFound,
        misc,
        error,
        getLocation,
        toggleWorkItem,
        addSnapshotToWorkItem,
        deleteSnapshotFromWorkItem,
        submitReport
    }
})