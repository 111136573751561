import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGPSStore = defineStore('GPS', () => {
    const forceGPS = ref(false);
    const gpsErrorMessage = ref('');
    const gpsError = ref(false);
    const gpsLoading = ref<boolean>(false);
    const gpsLocation = ref<any>(null);
    const gpsFound = ref(false);

    const translateCoordinates = (distance: number, Lat: number, Lng: number, directus: string) => {
        let newLat = 0;
        let newLng = 0;

        if (directus === 'northWest') {
            newLat = translateLatitude(distance, Lat);
            newLng = translateLongitude(distance * - 1, newLat, Lng);
        }
        if (directus === 'northEast') {
            newLat = translateLatitude(distance, Lat);
            newLng = translateLongitude(distance, newLat, Lng);
        }
        if (directus === 'southWest') {
            newLat = translateLatitude(distance * - 1, Lat);
            newLng = translateLongitude(distance * - 1, newLat, Lng);
        }
        if (directus === 'southEast') {
            newLat = translateLatitude(distance * - 1, Lat);
            newLng = translateLongitude(distance, newLat, Lng);
        }

        return [newLng, newLat];
    }

    function translateLatitude(distance: number, Lat: number) {
        const earth = 6378.137;
        const pi = Math.PI;
        const m = (1 / ((2 * pi / 360) * earth)) / 1000;

        return Lat + (distance * m);
    }

    function translateLongitude(distance: number, Lat: number, Lng: number) {
        const earth = 6378.137;  //radius of the earth in kilometer
        const pi = Math.PI;
        const cos = Math.cos;
        const m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

        return Lng + (distance * m) / cos(Lat * (pi / 180));
    }

    function calculateDistanceBetweenCoordinates(lat1: number, lon1: number, lat2: number, lon2: number) {
        const R = 6371; // Radius of the earth in km
        const dLat = _deg2rad(lat2 - lat1);  // _deg2rad below
        const dLon = _deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(_deg2rad(lat1)) * Math.cos(_deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c * 1000; // Distance in m
        return d;
    }

    function _deg2rad(deg: number) {
        return deg * (Math.PI / 180)
    }
    
    return {
        forceGPS,
        gpsErrorMessage,
        gpsError,
        gpsLoading,
        gpsLocation,
        gpsFound,
        translateCoordinates,
        translateLatitude,
        translateLongitude,
        calculateDistanceBetweenCoordinates
    };
});