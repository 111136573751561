import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useLocationStore } from './locations';

export const useQrStore = defineStore('qr', () => {
    const qrCode = ref<string|null>(null);
    const locationStore = useLocationStore();
    const scannedAt = ref<number>(0);


    watch (qrCode, () => {
        if (qrCode.value !== null) {
            locationStore.getFromQrCodeId(qrCode.value);
        }
    })

    return {
        qrCode,
        scannedAt
    }
});