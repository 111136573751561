import { createRouter, createWebHistory } from 'vue-router'
import { useQrStore } from '@/stores/qr'
import HomeView from '../views/HomeView.vue'
import ScanView from '../views/ScanView.vue'
import LoginView from '../views/LoginView.vue'
import QrCodeView from '../views/QrCodeView.vue'
import NearbyLocationsView from '../views/NearbyLocationsView.vue'
import GeoLocationView from '../views/GeoLocationView.vue'
import ReportView from '../views/ReportView.vue'
import { storeToRefs } from 'pinia'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/scan',
    name: 'scan',
    component: ScanView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/l/:id',
    name: 'qrScan',
    component: QrCodeView
  },
  {
    path: '/list',
    name: 'list',
    component: NearbyLocationsView
  },
  {
    path: '/geolocation',
    name: 'geolocation',
    component: GeoLocationView
  },
  {
    path: '/report',
    name: 'report',
    component: ReportView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  const { qrCode, scannedAt } = storeToRefs(useQrStore());
  if (to.name === 'qrScan') {
    if (typeof(to.params.id) === 'string')
      {
        qrCode.value = to.params.id;
      }
  }

  if (to.query?.hash) {

    const timestamp = atob(String(to.query.hash));
    scannedAt.value = parseInt(timestamp);
    
    delete(to.query.hash);
    router.replace(to)
  }

  if (
      qrCode.value !== null
      && to.path != '/scan'
      && scannedAt.value < (Math.floor(Date.now() / 1000) - 60*5)) {
    return '/scan';
  }

  return true;
})

export default router
