import { defineStore, storeToRefs } from 'pinia';
import { inject, ref } from 'vue';
import { useGPSStore } from './gps';

const $baseUrl = process.env.VUE_APP_API_BASE_URL;
const $topSecretKey = process.env.VUE_APP_API_SECRET_KEY;

export const useLoginStore = defineStore('login', () => {
    const loginStatus = ref<boolean>(false);
    const loginError = ref<boolean>(false);
    const axios: any = inject('axios')
    const { gpsFound, gpsLocation } = storeToRefs(useGPSStore());
    const contractorId = ref<number>();

    const login = async (password: string) => {
        const path = $baseUrl + '/items/Contractors?filter[AccessCode][_eq]=' + password + '&fields=id,CompanyName';
        const options = {
            headers: {
            Authorization: 'Bearer ' + $topSecretKey
            }
        }
        axios.get(path, options)
        .then((response: {data: any}) => {
            if (response.data.data.length === 0) {
                loginError.value = true;
                return;
            }
            loginError.value = false;
            loginStatus.value = true;
            contractorId.value = response.data.data[0].id;
            updateLoginLog(response.data.data[0].id);

        })
        .catch((error: any) => {
            console.log(error);
            loginError.value = true;
        })
    }

    const updateLoginLog = async (user: string) => {
        const path = $baseUrl + '/items/Contractors/' + user;
        const options = {
          headers: {
            Authorization: 'Bearer ' + $topSecretKey
          }
        }
        axios.patch(path, {last_login: new Date().toISOString()}, options)
        .catch((error: any) => {
          console.log(error);
        })
    }

    const updateNewLoginLog = async (location: number) => {
        const path = $baseUrl + '/items/login_log';
        const options = {
          headers: {
            Authorization: 'Bearer ' + $topSecretKey
          }
        }
        
        let coordinates = null;
        if (gpsFound.value) {
            coordinates = {
                "type": "Point",
                "coordinates": [gpsLocation.value.lng, gpsLocation.value.lat]
            }
        }
        axios.post(path, {
            contractor: contractorId.value,
            location: location,
            coordinates: coordinates,
            unique_identifier: getUniqueIdentifier(),

        }, options)
        .catch((error: any) => {
          console.log(error);
        })
        return true;
    }

    const logout = async () => {
        loginStatus.value = false;
        loginError.value = false;
    }

    const getUniqueIdentifier = () => {
        let id = localStorage.getItem('haviaId');
        if (id === null) {
            id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            id += '_'+new Date().toISOString().replace(/ /g, '_');
            localStorage.setItem('haviaId', id);
        }

        return id;
    }

    return {
        login,
        logout,
        updateNewLoginLog,
        loginStatus,
        loginError,
        contractorId,
    }
});