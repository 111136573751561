import { defineStore, storeToRefs } from 'pinia';
import { inject, ref } from 'vue';
import { useGPSStore } from './gps';
import { useLoginStore } from './login';

const $baseUrl = process.env.VUE_APP_API_BASE_URL;
const $topSecretKey = process.env.VUE_APP_API_SECRET_KEY;
const $dawaBaseUrl = process.env.VUE_APP_DAWA_BASE_URL;

export const useLocationStore = defineStore('location', () => {
    const axios: any = inject('axios')
    const gps = useGPSStore();

    const { contractorId } = storeToRefs(useLoginStore());

    const location = ref<any>(null);
    const locationErrorMessage = ref();
    const locationError = ref(false);
    const foundLocation = ref(false);
    const addressString = ref('');
    const addressId = ref('');
    const nearbyLocations = ref<any>([]);


    const getFromQrCodeId = async(qrCodeId: string): Promise<void> => {
        const path = $baseUrl + '/items/Locations';
        const options = {
            headers: {
                Authorization: 'Bearer ' + $topSecretKey
            },
            params: {
                fields: [
                    'id',
                    'Contractors.*',
                    'Address'
                ],
                filter: {
                    _and: [
                        {
                            qrcode_id: {
                                _eq: qrCodeId
                            }
                        },
                        {
                            status: {
                                _neq: 'archived'
                            }
                        }
                    ]
                }
            }
        }
        axios.get(path, options)
        .then((response: {data: any}) => {
            if (response.data.data.length === 0) {
                locationErrorMessage.value="Ukendt QR-Kode."
                locationError.value = true;
                foundLocation.value = false;
                return;
            }
            foundLocation.value = true;
            locationError.value = false;
            location.value = response.data.data[0];
            addressId.value = response.data.data[0].id;
            addressString.value = response.data.data[0].Address;
        })
        .catch(() => {
            locationErrorMessage.value="Fejl ved læsning af QR-Kode."
            foundLocation.value = false;
            locationError.value = true;
        });
    }

    const setLocation = (locationData: any) => {
        location.value = locationData;
        foundLocation.value = true;
        locationError.value = false;
        addressId.value = locationData.id;
        addressString.value = locationData.Address;
    }

    const getNearByLocations = async (lat: number, lng: number, radius: number): Promise<void> => {
        nearbyLocations.value = [];
        const boundingBox = getBoundingBox(lat, lng, radius);

        const path = $baseUrl + '/items/Locations';
        const options = {
            headers: {
                Authorization: 'Bearer ' + $topSecretKey
            },
            params: {
                fields: [
                    '*',
                    'Contractors.*',
                    'WorkItems.Service.*'
                ],
                filter: {
                    _and: [
                        {
                            Position: {
                                _intersects_bbox: {
                                    type: "Polygon",
                                    coordinates: [boundingBox]
                                }
                            }
                        },
                        {
                            Contractors: {
                                Contractors_id: {
                                    _in: [contractorId.value ?? '99999999']
                                }
                            }
                        },
                        {
                            WorkItems: {
                                Service: {
                                    Allow_GPS: {
                                        _eq: true
                                    }
                                }
                            }
                        },
                        {
                            status: {
                                _neq: 'archived'
                            }
                        }
                    ]
                }
            }
        }

        return new Promise((resolve) => {
            axios.get(path, options)
            .then((response: {data: any}) => {
                nearbyLocations.value = response.data.data;
                nearbyLocations.value = nearbyLocations.value.map((location: any) => {
                    location.Distance = Math.round(gps.calculateDistanceBetweenCoordinates(lat, lng, location.Position.coordinates[1], location.Position.coordinates[0]));
                    return location;
                })
                .sort((a: any, b: any) => a.Distance - b.Distance);

                resolve();
            })
            .catch((error: any) => {
                resolve();
                console.error("error getting nearby locations", error);
            })
        });
    }

    const getBoundingBox = (lat: number, lng: number, radius: number) => {
        const cornerNorthWest = gps.translateCoordinates(radius, lat, lng, 'northWest');
        const cornerNorthEast = gps.translateCoordinates(radius, lat, lng, 'northEast');
        const cornerSouthEast = gps.translateCoordinates(radius, lat, lng, 'southEast');
        const cornerSouthWest = gps.translateCoordinates(radius, lat, lng, 'southWest');

        return [
            cornerNorthWest,
            cornerNorthEast,
            cornerSouthEast,
            cornerSouthWest,
            cornerNorthWest
        ]
    }
    
    const addressLookupByCoordinates = async (lat: number, lng: number): Promise<string> => {
        let address = `Der blev ikke fundet en adresse på lokationen (${lat}, ${lng})`;
        const path = $dawaBaseUrl + '/adgangsadresser/reverse?x=' + lng + '&y=' + lat + '&format=json';
        const options = {
        }

        return new Promise((resolve) => {
            axios.get(path, options)
            .then((response: {data: any}) => {
                address = response.data.adressebetegnelse;
            })
            .catch((error: any) => {
                console.error("error getting address", error);
            })
            .finally(() => {
                resolve(address);
            });
        });
    }

    return {
        location,
        locationErrorMessage,
        locationError,
        foundLocation,
        addressString,
        addressId,
        nearbyLocations,
        addressLookupByCoordinates,
        getFromQrCodeId,
        getNearByLocations,
        setLocation
    }
});